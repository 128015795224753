import * as React from 'react';
import { PageProps } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { PageHeadline } from '../components/page-headline/page-headline';
import { CookiePolicyRules } from '../ui/pages/cookie-policy/cookie-policy-rules/cookie-policy-rules';

const CookiePolicyPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'cookie_policy.seo_title' })}
        description={intl.formatMessage({
          id: 'cookie_policy.seo_description',
        })}
      />
      <div className="container">
        <PageHeadline>
          <FormattedMessage id="cookie_policy.headline" />
        </PageHeadline>
        <CookiePolicyRules />
      </div>
    </MainTemplate>
  );
};

export default CookiePolicyPage;
