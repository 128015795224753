import * as React from 'react';
import * as styles from './cookie-policy-rules.module.scss';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';

export const CookiePolicyRules = () => {
  const intl = useIntl();

  return (
    <div className={styles.content}>
      <p>
        <FormattedMessage id="cookie_policy.sub_headline" />
      </p>

      <h2>
        <FormattedMessage id="cookie_policy.content.what_are_cookies.question" />
      </h2>
      <p>
        <FormattedMessage id="cookie_policy.content.what_are_cookies.answer" />
      </p>

      <h2>
        <FormattedMessage id="cookie_policy.content.what_cookies_do_we_use.question" />
      </h2>
      <p>
        <FormattedMessage id="cookie_policy.content.what_cookies_do_we_use.first_party_cookies" />
      </p>
      <p>
        <FormattedMessage id="cookie_policy.content.what_cookies_do_we_use.third_party_cookies.description" />
      </p>
      <p
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'cookie_policy.content.what_cookies_do_we_use.third_party_cookies.types.analytics',
          }),
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'cookie_policy.content.what_cookies_do_we_use.third_party_cookies.types.social',
          }),
        }}
      />

      <h2>
        <FormattedMessage id="cookie_policy.content.how_to_deactivate.question" />
      </h2>
      <p
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'cookie_policy.content.how_to_deactivate.answer',
          }),
        }}
      />
    </div>
  );
};
